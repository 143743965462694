import request from '@/utils/request'

// 查询路线规划列表
export function listRoutePlanning(query) {
  return request({
    url: '/iot/routePlanning/list',
    method: 'get',
    params: query
  })
}

// 查询路线规划详细
export function getRoutePlanning(id) {
  return request({
    url: '/iot/routePlanning/' + id,
    method: 'get'
  })
}

// 新增路线规划
export function addRoutePlanning(data) {
  return request({
    url: '/iot/routePlanning',
    method: 'post',
    data: data
  })
}

// 修改路线规划
export function updateRoutePlanning(data) {
  return request({
    url: '/iot/routePlanning',
    method: 'put',
    data: data
  })
}

// 删除路线规划
export function delRoutePlanning(id) {
  return request({
    url: '/iot/routePlanning/' + id,
    method: 'delete'
  })
}

// 导出路线规划
export function exportRoutePlanning(query) {
  return request({
    url: '/iot/routePlanning/export',
    method: 'get',
    params: query
  })
}

// 获取路线下的
export function getLanes(id) {
  return request({
    url: '/iot/routePlanning/getLanes/' + id,
    method: 'get'
  })
}
// 计算线路预计里程和预计需要花费的时间
export function calculationMileageAndCostTime(query) {
  return request({
    url: '/iot/routePlanning/calculationMileageAndCostTime',
    method: 'post',
    data: query
  })
}
