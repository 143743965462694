<template>
  <div class="bottom-button-group">
    <a-button type="primary" class="left" @click="handleConfirm" :disabled="disabled">
      {{ primaryText }}
    </a-button>
    <a-button class="right" @click="handleCancel">{{ secondText }}</a-button>
  </div>
</template>
<script>
export default {
  props: {
    primaryText: {
      type: String,
      default: () => {}
    },
    secondText: {
      type: String,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    handleConfirm() {
      this.$emit('onConfirm', null)
    },
    handleCancel() {
      this.$emit('onCancel', null)
    }
  }
}
</script>
<style lang="less" scoped>
.bottom-button-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  height: 40px !important;
  > .left {
    margin-right: 5px;
  }
  .right {
    margin-left: 5px;
  }
}
</style>
