var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bottom-button-group" },
    [
      _c(
        "a-button",
        {
          staticClass: "left",
          attrs: { type: "primary", disabled: _vm.disabled },
          on: { click: _vm.handleConfirm },
        },
        [_vm._v(" " + _vm._s(_vm.primaryText) + " ")]
      ),
      _c(
        "a-button",
        { staticClass: "right", on: { click: _vm.handleCancel } },
        [_vm._v(_vm._s(_vm.secondText))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }